/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { DatePicker, Input, Select, Col } from 'antd';
import { isEmpty, isUndefined } from 'underscore';
import Sticky from 'react-sticky-el';
import '@rc-component/color-picker/assets/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';
import Icon from '../../../elements/Icon';
import ChartMultiDropDown from '../../ChartComponents/ChartMultiDropDown';
import PieChart from '../../ChartComponents/PieChart';
import ColorRule from '../ColorRule';
import { getChartIconList } from '../../../../core/utils/functions';

const { Option } = Select;
const FormItem = Form.Item;

class ThreeDPieChartWithCustomLabelForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    getPreviewData = () => {
        const { form } = this.props;
        const { getFieldValue } = form;

        const input_data = getFieldValue('input_data');
        const mappedIcons = getChartIconList();

        return input_data.map( (data) => (
            {
                ...data,
                icon: mappedIcons[data.icon],
            }
        ));
    }

    onInputUpdates = () => {
        const { getChartDataFromInput } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            getChartDataFromInput();
        }, 700);
    }

    getDataSet = () => {
        const { form, getChartDataFromInput, data } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const formulas = [
            {
                name: 'Highest value found',
                key: 1,
            },
            {
                name: 'Average (mean) * default',
                key: 2,
            },
            {
                name: 'Average (median)',
                key: 3,
            },
            {
                name: 'Max value in data',
                key: 4,
            },
            {
                name: 'Min value in data',
                key: 5,
            },
            {
                name: 'Sum of all values',
                key: 6,
            },

        ];
        const formats = [
            {
                name: 'Integer',
                key: 1,
            },
            {
                name: 'Decimal',
                key: 2,
            },
            {
                name: 'Hours & Mins',
                key: 3,
            },
            {
                name: 'Days & Hours',
                key: 4,
            },
            {
                name: 'Miles',
                key: 5,
            },
            {
                name: 'Km',
                key: 6,
            },
            {
                name: 'Kg',
                key: 7,
            },

        ];

        const fieldCounter = getFieldValue('input_dataList');
        const input_data = getFieldValue('input_data');
        let eventType = '';
        let displayReportCacheFields = true;

        return fieldCounter.map((k, num) => {

            if (!isUndefined(input_data[num]) && input_data[num].event_type) {
                eventType = input_data[num].event_type;
            }

            displayReportCacheFields = [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(eventType, 10));

            let main_label_text = '';
            let secondary_label_text = '';
            let colorRuleData = [];
            let color_type = 'fixed';
            let number_format = null;
            let formula = null;

            if(!isUndefined(data) && !isEmpty(data[k])) {
                main_label_text = data[k].main_label_text;
                secondary_label_text = data[k].secondary_label_text;
                colorRuleData = (data[k]?.color_type == 'smart-rule') ? data[k].rules : data[k].color;
                color_type = data[k]['color_type'];
                number_format = parseInt(data[k].number_format);
                formula = parseInt(data[k].formula);
            }
            return (
                (<div key={k}>
                    <div className="ant-col ant-form-item ant-form-item-label">
                        <label title={`Data ${num + 1}`}>Data {num + 1}</label>
                    </div>
                    <div className="data-box">
                        <FormItem label="Main label text">
                            {getFieldDecorator(`input_data[${k}][main_label_text]`, {
                                rules: [
                                    {
                                        // required: true,
                                        message: 'Please enter label text',
                                    },
                                ],
                                initialValue: main_label_text
                            })(
                                <Input
                                    onChange={() => this.onInputUpdates()}
                                    placeholder="Main label text" />,
                            )}
                        </FormItem>

                        <FormItem label="Secondary label text">
                            {getFieldDecorator(`input_data[${k}][secondary_label_text]`, {
                                rules: [
                                    {
                                        // required: true,
                                        message: 'Please enter label text',
                                    },
                                ],
                                initialValue: secondary_label_text,
                            })(
                                <Input
                                    onChange={() => this.onInputUpdates()}
                                    placeholder="Secondary label text" />,
                            )}
                        </FormItem>
                        <br />

                        <ColorRule
                            onChange={() => getChartDataFromInput()}
                            form={form}
                            mode={color_type}
                            data={colorRuleData}
                            name={`input_data[${k}]`} />

                        {displayReportCacheFields ? (
                            <>
                                <FormItem label="Number Format">
                                    {getFieldDecorator(`input_data[${k}][number_format]`, {
                                        rules: [],
                                        initialValue: number_format,
                                    })(
                                        <Select
                                            onChange={() => getChartDataFromInput()}
                                            style={{ width: '100%' }}
                                            placeholder="Number Format"
                                            suffixIcon={
                                                <Icon name="triangle-down" />
                                            }>
                                            {
                                                formats.map((v, i) => (
                                                    /* eslint-disable-next-line react/no-array-index-key */
                                                    (<Option key={i} value={v.key}>{v.name}</Option>)
                                                ))
                                            }

                                        </Select>,
                                    )}
                                </FormItem>

                                <FormItem label="Formula">
                                    {getFieldDecorator(`input_data[${k}][formula]`, {
                                        rules: [],
                                        initialValue: formula,
                                    })(
                                        <Select
                                            onChange={() => getChartDataFromInput()}
                                            style={{ width: '100%' }}
                                            placeholder="Formula"
                                            suffixIcon={
                                                <Icon name="triangle-down" />
                                            }>
                                            {
                                                formulas.map((v, i) => (
                                                    /* eslint-disable-next-line react/no-array-index-key */
                                                    (<Option key={i} value={v.key}>{v.name}</Option>)
                                                ))
                                            }

                                        </Select>,
                                    )}
                                </FormItem>
                            </>
                        )

                            : <p style={{ fontWeight: 'bold', textAlign: 'left' }}>Formula = Event Count</p>}
                    </div>
                    <br />
                </div>)
            );
        });
    }

    render() {
        const { form, saveDatatypeValues, getChartDataFromInput, division, driver, asset, setTimeframe, smartWidget, data, telematicsRetentionDays } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const dataSet = this.getDataSet();

        return (
            <>
                <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="input-data-col">
                        { dataSet }
                        <p className="field-label">Default data view</p>

                        <ChartMultiDropDown
                            onDataChange={(optionsData) => saveDatatypeValues(optionsData)}
                            divisions={[...division.divisionOptions]}
                            drivers={[...driver.driverList]}
                            assets={[...asset.options]} />
                    </div>
                </Col>
                <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Sticky>
                        <div className="smart-widget-preview">
                            <h2>Widget Preview</h2>
                            <FormItem className="widget-preview-date">
                                {getFieldDecorator('date', {
                                    initialValue: dayjs(),
                                    rules: [
                                    ],
                                })(
                                    <DatePicker
                                        onChange={() => getChartDataFromInput()}
                                        format="DD/MM/YYYY" 
                                        minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    />,
                                )}
                            </FormItem>
                            <br />

                            <PieChart
                                heightRatio={2}
                                timeframe={smartWidget?.presetWidget?.timeframe || 'hourly'}
                                onTimeframeChange={(timeframe) => setTimeframe(timeframe)}
                                rightMenuItems={[]}
                                tooltipText={smartWidget?.presetWidget?.name || 'This is tooltip text'}
                                data={smartWidget.chartData}
                                // data1Label={getFieldValue('input_data[1][label_text]')}
                                // data1Icon={mappedIcons[getFieldValue('input_data[1][icon]')]}
                                // data2Label={getFieldValue('input_data[2][label_text]')}
                                // data2Icon={mappedIcons[getFieldValue('input_data[2][icon]')]}
                                onAllOptionChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                                divisions={[...division.divisionOptions]}
                                drivers={[...driver.driverList]}
                                assets={[...asset.options]} />

                        </div>
                    </Sticky>
                </Col>
            </>
        );
    }
}

ThreeDPieChartWithCustomLabelForm.defaultProps = {
    saveDatatypeValues: () => {},
    getChartDataFromInput: () => {},
    setTimeframe: () => {},
};

ThreeDPieChartWithCustomLabelForm.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    saveDatatypeValues: PropTypes.func,
    getChartDataFromInput: PropTypes.func,
    setTimeframe: PropTypes.func,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        // newImageId: state.smartWidget.newImageId,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ThreeDPieChartWithCustomLabelForm);
