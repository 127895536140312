/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { DatePicker, Input, Select, Col } from 'antd';
import { isEmpty, isUndefined } from 'underscore';
import Sticky from 'react-sticky-el';
import '@rc-component/color-picker/assets/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';
import Icon from '../../../elements/Icon';
import ChartMultiDropDown from '../../ChartComponents/ChartMultiDropDown';
import MultipleIconsChart from '../../ChartComponents/MultipleIconsChart';
import ColorRule from '../ColorRule';
import IconPicker from '../IconPicker';
import { getChartIconList } from '../../../../core/utils/functions';
import Avatar from '../../../elements/Avatar';

const { Option } = Select;
const FormItem = Form.Item;

class MultiIconForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    onInputUpdates = () => {
        const { getChartDataFromInput } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            getChartDataFromInput();
        }, 700);
    }

    getDataSet = () => {
        const { form, getChartDataFromInput, data } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const fieldCounter = getFieldValue('input_dataList');
        const input_data = getFieldValue('input_data');
        let eventType = '';
        let displayReportCacheFields = true;

        return fieldCounter.map((k, num) => {

            if (!isUndefined(input_data[num])) {
                eventType = input_data[num].event_type;
            }
            displayReportCacheFields = [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(eventType, 10));

            let colorRuleData = [];
            let icon = 1;
            let icon_hover_text = '';
            let color_type = 'fixed';
            let formula = '';
            if(!isUndefined(data) && !isEmpty(data[k])) {
                colorRuleData = (data[k]?.color_type == 'smart-rule') ? data[k].rules : data[k].color;
                icon = parseInt(data[k]['icon']);
                icon_hover_text = data[k]['icon_hover_text'];
                color_type = data[k]['color_type'];
                formula = parseInt(data[k].formula);
            }
            return (
                (<div key={k}>
                    <div className="ant-col ant-form-item ant-form-item-label">
                        <label title={`Data ${num + 1}`}>Data {num + 1}</label>
                    </div>
                    <div className="data-box">
                        <IconPicker form={form} name={`input_data[${k}][icon]`} defaultIcon={icon} onChange={() => getChartDataFromInput()} />
                        <br />

                        <FormItem label="Icon Hover Text">
                            {getFieldDecorator(`input_data[${k}][icon_hover_text]`, {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter label text',
                                    },
                                ],
                                initialValue: icon_hover_text
                            })(
                                <Input
                                    placeholder="label text"
                                    onChange={() => this.onInputUpdates()} />,
                            )}
                        </FormItem>
                        <br />

                        <ColorRule
                            onChange={() => getChartDataFromInput()}
                            form={form}
                            mode={color_type}
                            data={colorRuleData}
                            name={`input_data[${k}]`} />

                        {displayReportCacheFields ? (
                            <FormItem label="Formula">
                                {getFieldDecorator(`input_data[${k}][formula]`, {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select formula',
                                        },
                                    ],
                                    initialValue: formula
                                })(
                                    <Select
                                        onChange={() => getChartDataFromInput()}
                                        style={{ width: '100%' }}
                                        placeholder="Formula"
                                        suffixIcon={
                                            <Icon name="triangle-down" />
                                        }>
                                        {
                                            [
                                                {
                                                    name: 'Highest value found',
                                                    key: 1,
                                                },
                                                {
                                                    name: 'Average (mean) * default',
                                                    key: 2,
                                                },
                                                {
                                                    name: 'Average (median)',
                                                    key: 3,
                                                },
                                                {
                                                    name: 'Max value in data',
                                                    key: 4,
                                                },
                                                {
                                                    name: 'Min value in data',
                                                    key: 5,
                                                },
                                                {
                                                    name: 'Sum of all values',
                                                    key: 6,
                                                },
                                            ].map((v, i) => (
                                                /* eslint-disable-next-line react/no-array-index-key */
                                                (<Option key={i} value={v.key}>{v.name}</Option>)
                                            ))
                                        }
                                    </Select>,
                                )}
                            </FormItem>
                        )
                            : <p style={{ fontWeight: 'bold', textAlign: 'left' }}>Formula = Event Count</p>}
                    </div>
                    <br />
                </div>)
            );
        });
    }

    uploadImage = (data) => {
        const { actions, getChartDataFromInput } = this.props;
        actions.uploadWidgetImageAttrRequest({
            image: data,
            attr: 'logo',
        });
        setTimeout(() => getChartDataFromInput(), 1000);
    }

    render() {
        const { form, saveDatatypeValues, getChartDataFromInput, division, driver, asset, setTimeframe, smartWidget, data, telematicsRetentionDays } = this.props;
        const { getFieldDecorator } = form;

        const dataSet = this.getDataSet();
        const mappedIcons = getChartIconList();

        let preview = (
            <div className="smart-widget-preview">
                <h2>Widget Preview</h2>
                <FormItem className="widget-preview-date">
                    {getFieldDecorator('date', {
                        initialValue: dayjs(),
                        rules: [
                        ],
                    })(
                        <DatePicker
                            onChange={() => getChartDataFromInput()}
                            format="DD/MM/YYYY" 
                            minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                        />,
                    )}
                </FormItem>
                <br />

                <MultipleIconsChart
                    data={smartWidget.chartData}
                    chartTitle={form.getFieldValue('title')}
                    chartIcon={mappedIcons[form.getFieldValue('primary_icon') || 1]}
                    heightRatio={2}
                    timeframe={smartWidget?.presetWidget?.timeframe || 'hourly'}
                    onTimeframeChange={(timeframe) => setTimeframe(timeframe)}
                    rightMenuItems={[]}
                    tooltipText={smartWidget?.presetWidget?.name || 'This is tooltip text'}
                    unit="Miles"
                    subtitle="Total miles driven this week"
                    onAllOptionChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                    divisions={[...division.divisionOptions]}
                    drivers={[...driver.driverList]}
                    assets={[...asset.options]} />
            </div>
        );

        const isSticky = window.innerWidth > 767;
        if (isSticky) {
            preview = <Sticky>{ preview }</Sticky>;
        }

        let defaultIcon = 1;
        let main_label_text = '';
        let logo = null;
        if (typeof data !== 'undefined') {
            defaultIcon = data && data[0] && data[0].icon && parseInt(data[0].icon, 10);
            main_label_text = (data && data[0] && data[0].main_label_text) || '';
            logo = (data && data[0] && data[0].logo) || '';
        }
        return (
            <>
                <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="input-data-col">
                        <IconPicker form={form} name="input_data[0][primary_icon]" label="Primary Icon" defaultIcon={defaultIcon} onChange={() => getChartDataFromInput()} />
                        <br />

                        <FormItem label="Title">
                            {getFieldDecorator('input_data[0][main_label_text]', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter title',
                                    },
                                ],
                                initialValue: main_label_text,
                            })(
                                <Input
                                    placeholder="title"
                                    onChange={() => this.onInputUpdates()} />,
                            )}
                        </FormItem>
                        <br />

                        <FormItem label="Logo">
                            <Avatar pictureUrl={logo} onChange={(data) => this.uploadImage(data)} />
                        </FormItem>
                        <br />

                        { dataSet }

                        <br />

                        <p className="field-label">Default data view</p>

                        <ChartMultiDropDown
                            onDataChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                            divisions={[...division.divisionOptions]}
                            drivers={[...driver.driverList]}
                            assets={[...asset.options]} />
                    </div>
                </Col>
                <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                    { preview }
                </Col>
            </>
        );
    }
}

MultiIconForm.defaultProps = {
    saveDatatypeValues: () => {},
    getChartDataFromInput: () => {},
    setTimeframe: () => {},
};

MultiIconForm.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    saveDatatypeValues: PropTypes.func,
    getChartDataFromInput: PropTypes.func,
    setTimeframe: PropTypes.func,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        // newImageId: state.smartWidget.newImageId,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiIconForm);
