import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Input, Button, Row, Col, 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';
import { formatEventTypes } from '../../../core/utils/functions';
import Icon from '../../../components/elements/Icon';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';

dayjs.extend(customParseFormat);

class AssetEventHistoryFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getEventTypesRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { form, event_types, driverList, telematicsRetentionDays } = this.props;
        const { getFieldDecorator } = form;
        const updatedEventTypes = formatEventTypes(event_types);

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Row>
                    <Col span={12}>
                        <Form.Item label="Date from">
                            {getFieldDecorator('date_from', { initialValue: dayjs() })(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Date to">
                            {getFieldDecorator('date_to', { initialValue: dayjs() })(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Event type">
                    {getFieldDecorator('event_type', {

                    })(
                        <Select
                            placeholder="Select event type"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="all">All</Select.Option>
                            {updatedEventTypes.map((eventType, eventTypeIndex) => <Select.Option key={eventTypeIndex} value={eventType.id}>{eventType.key}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver')(
                        <Select
                            mode="multiple"
                            placeholder="Select driver"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="all">Any</Select.Option>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division', {

                    })(
                        <Input placeholder="Division" />,
                    )}
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Postcode">
                            {getFieldDecorator('postcode', {

                            })(
                                <Input placeholder="Postcode" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Radius">
                            {getFieldDecorator('miles', {

                            })(
                                <Input placeholder="Miles" />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const AssetEventHistory = Form.create({ name: 'event_filter_form' })(AssetEventHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        driverList: state.driver.driverList,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetEventHistory);
