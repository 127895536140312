import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { Button, Input } from 'antd';
import * as eventsActions from '../../../core/events/eventsActions';
import srcISSLogo from '../../../assets/images/iss-logo-silver-black.png';
import srcFleetclearLogo from '../../../assets/images/fleetclear-logo.svg';

class PasswordScreenFrom extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, params, shardId } = this.props;
                actions.verifyReportCodeRequest({
                    username: values.email,
                    password: values.password,
                    code: params.code,
                    shardId: shardId || '1',
                });
            }
        });
    };

    render() {
        const { form, params } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img src={srcISSLogo} className="login__logo-iss" alt="ISS" />
                        <img src={srcFleetclearLogo} className="login__logo-fleet" alt="Fleetclear" />
                        <h1 className="login__heading">Enter password to view event</h1>
                    </div>
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input event password!',
                                    },
                                ],
                            })(<Input placeholder="Password" />)}
                        </Form.Item>
                        <Button htmlType="submit" className="btn btn-bordered">
                            View Event
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
}

PasswordScreenFrom.propTypes = {
    actions: PropTypes.object.isRequired,
};

const PasswordScreen = Form.create()(PasswordScreenFrom);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        shardId: state.global.shardId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PasswordScreen);
