/* global window */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Input, Button, Row, Col, 
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatEventTypes } from '../../../core/utils/functions';
import Icon from '../../../components/elements/Icon';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';
import * as driverActions from '../../../core/driver/driverActions';
import * as globalActions from '../../../core/global/globalActions';

dayjs.extend(customParseFormat);

class EventFilterForm extends Component {
    componentDidMount() {
        const {
            actions,
            driverList,
        } = this.props;
        actions.getEventTypesRequest({ is_event: true });
        actions.getAssetOptionsRequest();
        actions.getDriverListRequest();
        // if (driverList.length <= 0) {
        //     actions.getDriverListRequest();
        // }
        actions.getDivisionsRequest();

        const {
            form,
            eventsTabFilter,
        } = this.props;

        const dateFrom = dayjs(eventsTabFilter.date_from);
        const dateTo = dayjs(eventsTabFilter.date_to);

        form.setFieldsValue({
            date_from: dateFrom,
            date_to: dateTo,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { form, eventsTabFilter } = this.props;
        const { date_from, date_to } = eventsTabFilter;
        const { date_from: old_date_from, date_to: old_date_to } = prevProps.eventsTabFilter;

        if (date_from !== old_date_from || date_to !== old_date_to) {
            const dateFrom = dayjs(eventsTabFilter.date_from);
            const dateTo = dayjs(eventsTabFilter.date_to);

            form.setFieldsValue({
                date_from: dateFrom,
                date_to: dateTo,
            });
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit, form } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const {
            form,
            event_types,
            assetOptions,
            driverList,
            divisionList,
            role,
            eventsTabFilter,
            assetId,
            dateFrom,
            dateTo,
            mapDate,
            telematicsRetentionDays,
        } = this.props;
        const { getFieldDecorator } = form;

        const params = eventsTabFilter;
        let assetInfoInitialVal = params && params.asset_info ? { initialValue: parseInt(params.asset_info, 10) } : {};
        const eventTypeInitialVal = params && params.event_types ? { initialValue: params.event_types } : {};
        const driverInitialVal = params && params.driver ? { initialValue: params.driver } : {};
        const divisionInitialVal = params && params.division ? { initialValue: params.division } : {};
        const postcodeInitialVal = params && params.postcode ? { initialValue: params.postcode } : {};
        const radiusInitialVal = params && params.miles ? { initialValue: params.miles } : {};

        if (window.location.pathname.includes('search')) {
            if (assetId) {
                assetInfoInitialVal = { initialValue: parseInt(assetId, 10) };
            }

            if (mapDate) {
                // If user comes from main map screen then use the map date to show events
            }

            if (dateFrom) {
            }

            if (dateTo) {
            }
        } else {
            if (eventsTabFilter && eventsTabFilter.date_from && eventsTabFilter.date_from !== '') {
            }

            if (eventsTabFilter && eventsTabFilter.date_to && eventsTabFilter.date_to !== '') {
            }
        }

        const updatedEventTypes = formatEventTypes(event_types);

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Asset name or reg">
                    {getFieldDecorator('asset_info', assetInfoInitialVal)(
                        <Select
                            showSearch
                            mode="multiple"
                            allowClear
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Asset name or reg"
                            suffixIcon={<Icon name="triangle-down" />}
                            placement='topRight'
                        >
                            {assetOptions.map((a) => (
                                <Select.Option key={a.id} value={a.id}>
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item label="Date from">
                            {getFieldDecorator('date_from')(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Date to">
                            {getFieldDecorator('date_to')(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Event type">
                    {getFieldDecorator('event_types', eventTypeInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Event type"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<Icon name="triangle-down" />}>
                            {updatedEventTypes.map((eventType) => <Select.Option key={eventType.id} value={eventType.id}>{eventType.key}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                { /* option.props.children.toLowerCase() */}
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', driverInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select driver"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<Icon name="triangle-down" />}>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division', divisionInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select division"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<Icon name="triangle-down" />}>
                            {divisionList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item label="Postcode">
                            {getFieldDecorator('postcode', postcodeInitialVal)(
                                <Input placeholder="Postcode" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Radius">
                            {getFieldDecorator('miles', radiusInitialVal)(
                                <Input placeholder="Miles" />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

EventFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    event_types: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    assetOptions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    divisionList: PropTypes.array.isRequired,
    eventsTabFilter: PropTypes.object.isRequired,
    assetId: PropTypes.string,
    dateFrom: PropTypes.string,
    mapDate: PropTypes.string,
};

const EventFilter = Form.create({ name: 'event_filter_form' })(EventFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        assetOptions: state.asset.options,
        driverList: state.driver.driverList,
        divisionList: state.asset.divisions,
        role: state.user.role,
        assetId: state.global.eventSearchFilters.assetId,
        mapDate: state.global.mapDate,
        dateFrom: state.global.eventSearchFilters.dateFrom,
        dateTo: state.global.eventSearchFilters.dateTo,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventFilter);
