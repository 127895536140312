import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import {
    Tooltip, DatePicker, Input, Slider, Dropdown,
} from 'antd';
import { format, set } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
import MemoizedDatePicker from '../../MemoizedDatePicker';
import { isEmpty } from 'underscore';
import TimeRangeSlider from '../../TimeRangeSlider';
import { CustomIcon } from '../elements/elements';
import './toolbar.scss';
import timeRangeStyles from './timeRangeStyles';
import timeRangeColours from './timeRangeColours';
import VideoFilledIconSvg from '../assets/icons/VideoFilledIconSvg';
import DeviceTimeline, { DEVICE_TIMELINE_STATUS_IDLE_KEY, DEVICE_TIMELINE_STATUS_MESSAGES, DEVICE_TIMELINE_STATUS_MOVING_KEY, DEVICE_TIMELINE_STATUS_STOPPED_KEY } from '../../DeviceTimeline';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: 100,
            error: false,
        };
    }

    componentDidMount() {
        const {
            highlightSlider, allowMultipleRangeSlider, sliderRangeMinValue, sliderRangeMaxValue,
        } = this.props;

        // calculating error
        let error = false;
        if (allowMultipleRangeSlider) {
            if (highlightSlider && highlightSlider[0] && highlightSlider[0][0]) {
                for (let i = 0; i < highlightSlider.length; i++) {
                    const vHighlighter = highlightSlider[i];
                    const beginHighlight = vHighlighter[0];
                    const endHighlight = vHighlighter[1];

                    if (beginHighlight <= sliderRangeMaxValue && endHighlight >= sliderRangeMaxValue) {
                        error = true;
                        break;
                    } else if (beginHighlight <= sliderRangeMinValue && endHighlight >= sliderRangeMinValue) {
                        error = true;
                        break;
                    } else if (sliderRangeMinValue < beginHighlight && sliderRangeMaxValue > endHighlight) {
                        error = true;
                        break;
                    }
                }
                this.setState({ error });
            }
        }
    }

    formatTooltip = (value) => {
        const {
            sliderStep, sliderValueUnit, isLive, assets, showNoAssetSelectedWarning, selectedAsset, 
            selectedDeviceTimeline, selectedDeviceGeofenceTimeline, mapAssetDate
        } = this.props;

        function secondsSinceMidnightToDate(secondsSinceMidnight) {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            date.setSeconds(secondsSinceMidnight);
            return date;
        }

        const valueDivision = (sliderValueUnit == 'seconds' ? 3600 : 60) / sliderStep;
        const convertValue = value / valueDivision; // convert minutes to hours and minutes
        let label = 'AM';
        const timeArray = convertValue.toString().split('.');
        while (timeArray[0].length < 2) {
            timeArray[0] = `0${timeArray[0]}`;
        }
        if (parseInt(timeArray[0], 10) >= 12) {
            label = 'PM';
            if (parseInt(timeArray[0], 10) > 12) {
                timeArray[0] = (parseInt(timeArray[0], 10) - 12).toString();
                while (timeArray[0].length < 2) {
                    timeArray[0] = `0${timeArray[0]}`;
                }
            }
        }
        if (!timeArray[1]) {
            timeArray[1] = '00';
        }
        while (timeArray[1].length < 2) {
            timeArray[1] += '0';
        }
        // timeArray[1] = timeArray[1].substring(0, 2)
        if (sliderValueUnit == 'seconds') {
            const minSecArrayRaw = (parseFloat(`.${timeArray[1]}`) * 60);
            const minSecArray = minSecArrayRaw.toString().split('.');
            timeArray[1] = minSecArray[0];
            if (!minSecArray[1]) {
                minSecArray[1] = '00';
            }
            timeArray[2] = Math.ceil((parseFloat(`.${minSecArray[1]}`) * 60)).toString();
            while (timeArray[2].length < 2) {
                timeArray[2] = `0${timeArray[2]}`;
            }
        } else {
            timeArray[1] = (Math.ceil(parseFloat(`.${timeArray[1]}`) * 60)).toString();
        }

        while (timeArray[1].length < 2) {
            timeArray[1] = `0${timeArray[1]}`;
        }

        let result = `${timeArray.join(':')} ${label}`;
        if (showNoAssetSelectedWarning && !isLive && isEmpty(assets)) {
            if (!selectedAsset) {
                result = (
                    <div>
                        <span style={{ whiteSpace: 'nowrap' }}>NO ASSETS SELECTED</span>
                        <br />
                        {result}
                    </div>
                );
            }
        }
        if (!isLive && selectedAsset) {
            const currentTime = dayjs(`${dayjs(mapAssetDate, 'DD/MM/YYYY').format('LL')} ${timeArray.join(':')} ${label}`);
            const statusRanking = [DEVICE_TIMELINE_STATUS_STOPPED_KEY, DEVICE_TIMELINE_STATUS_MOVING_KEY, DEVICE_TIMELINE_STATUS_IDLE_KEY];
            const selectedStatus = (selectedDeviceTimeline ?? [])
                .filter(item => currentTime.isBetween(dayjs(item.start_time), dayjs(item.end_time)))
                .reduce((carry, item) => {
                    if (statusRanking.indexOf(item.status) >= statusRanking.indexOf(carry.status)) {
                        const safeStatus = item.status ?? DEVICE_TIMELINE_STATUS_STOPPED_KEY;
                        const journeyStart = item.journey_start ? dayjs(item.journey_start).format('h:mm:ss a') : null;
                        const journeyEnd = item.journey_end ? dayjs(item.journey_end).format('h:mm:ss a') : null;
                        carry = {
                            status: item.status,
                            location: item.location,
                            journey_id: item.journey_id,
                            journey_name: item.journey_name,
                            journey_start: journeyStart,
                            journey_end: journeyEnd,
                            status_text: DEVICE_TIMELINE_STATUS_MESSAGES[safeStatus].trim()
                        }
                    }
                    return carry;
                }, {
                    status: null,
                    location: null,
                    journey_name: null,
                    journey_start: null,
                    journey_end: null,
                    status_text: DEVICE_TIMELINE_STATUS_MESSAGES[DEVICE_TIMELINE_STATUS_STOPPED_KEY],
                });

            const geofences = (selectedDeviceGeofenceTimeline ?? [])
                .filter(item => currentTime.isBetween(dayjs(item.start_time), dayjs(item.end_time)))
                .map(item => item.geofence_names.join(', '))
                .join(',');

            result = (
                <div>
                    {selectedStatus.journey_id ? (
                        <>
                            <span style={{ whiteSpace: 'nowrap' }}>{selectedStatus.journey_name}</span>
                            <br />
                            <span>{selectedStatus.journey_start} - {selectedStatus.journey_end} </span>
                            <br />
                        </>
                    ) : null}
                    <span style={{ whiteSpace: 'nowrap' }}>{selectedStatus.status_text}</span>
                    <br />
                    {result}
                    <br />
                    {geofences !== '' ? (
                        <>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                In Geo-fence{geofences.split(',').length > 1 ? 's' : ''}:
                                <br />
                                {geofences}
                            </span>
                        </>
                    ) : selectedStatus.status !== DEVICE_TIMELINE_STATUS_MOVING_KEY && selectedStatus.location ? (
                        <>
                                <span>{selectedStatus.location}</span>
                        </>
                    ) : null}
                </div>
            );
        }

        return result;
    };

    sliderChangeValue = (value) => {
        const { sliderChangeValue } = this.props;
        const formattedValue = this.formatTooltip(value);
        sliderChangeValue(value, formattedValue);
    };

    sliderRangeChangeValue = (selectedInterval) => {
        const { sliderRangeChangeValue } = this.props;
        const rangeMin = this.getSecondsFromDate(selectedInterval[0]);
        const value = this.getSecondsFromDate(selectedInterval[1]);
        const rangeMax = this.getSecondsFromDate(selectedInterval[2]);
        const formattedValue = this.formatTooltip(value);
        sliderRangeChangeValue(value, formattedValue, rangeMin, rangeMax);
    };

    handleOnUpdate = ({ error, time }) => {
        this.setState({ error });
        this.sliderRangeChangeValue(time);
    };

    getDateFromSeconds = (seconds) => {
        const today = new Date();
        const h = Math.floor(seconds / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 3600 % 60);
        return set(today, {
            hours: h, minutes: m, seconds: s, milliseconds: 0,
        });
    };

    getSecondsFromDate = (date) => {
        const h = date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();
        return (h * 3600) + (m * 60) + s;
    };

    getTickNumber = (startSecondsTimestamp, endSecondsTimestamp) => Math.round((endSecondsTimestamp - startSecondsTimestamp) / 60);

    getTickMinutesToDisplay = (timelineTotalHours, timelineTotalMinutes) => {
        let tickMinutesToDiplay;
        if (timelineTotalHours > 8) {
            tickMinutesToDiplay = [0];
        } else if (timelineTotalHours > 3) {
            tickMinutesToDiplay = [0, 30];
        } else if (timelineTotalHours > 2) {
            tickMinutesToDiplay = [0, 15, 30, 45];
        } else if (timelineTotalHours > 1) {
            tickMinutesToDiplay = [0, 10, 20, 30, 40, 50];
        } else if (timelineTotalMinutes > 10) {
            tickMinutesToDiplay = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
        } else {
            const arraySixty = Array(60);
            tickMinutesToDiplay = Array.from(arraySixty ? arraySixty.keys() : []);
        }
        return tickMinutesToDiplay;
    };

    getTickSecondsToDisplay = (minutes) => {
        let seconds = [0, 30];
        if (minutes < 1) {
            seconds = [0, 10, 20, 30, 40, 50];
        } else if (minutes < 2) {
            seconds = [0, 15, 30, 45];
        }
        return seconds;
    };

    getDisabledIntervals = () => {
        const { highlightSlider } = this.props;
        const disabledIntervals = [];
        if (highlightSlider && highlightSlider[0] && highlightSlider[0][0]) {
            highlightSlider.map((vHighlighter, vHighlighterIndex) => {
                const beginHighlight = vHighlighter[0];
                const endHighlight = vHighlighter[1];
                const disabledInterval = {
                    start: this.getDateFromSeconds(beginHighlight),
                    end: this.getDateFromSeconds(endHighlight),
                };
                disabledIntervals.push(disabledInterval);
            });
        }
        return disabledIntervals;
    };

    render() {
        const {
            isLive,
            buttons,
            showCenterMapInput,
            mapAssetDate,
            referenceSearchInput,
            onDateChanged,
            sliderMaxValue,
            sliderMinValue,
            sliderRangeMinValue,
            sliderRangeMaxValue,
            sliderValue,
            hideSlider,
            hideDatePicker,
            allowMultipleRangeSlider,
            step,
            forwardCallback,
            rewindCallback,
            timelineIncreaseOptions,
            selectedIncreaseOption,
            increaseOptionCallback,
            showResetBtn,
            resetCallback,
            markers,
            assets,
            selectedAsset,
            selectedDeviceTimeline,
            selectedDeviceGeofenceTimeline,
            sliderStep,
            sliderValueUnit,
            enableOfflineMode,
            telematicsRetentionDays,
        } = this.props;
        const { error } = this.state;
        const actualMapSlider = isLive ? sliderMaxValue : sliderValue;
        // Time Range variables
        const selectedTime = this.getDateFromSeconds(actualMapSlider);
        const intervalStart = this.getDateFromSeconds(sliderRangeMinValue);
        const intervalEnd = this.getDateFromSeconds(sliderRangeMaxValue);
        const selectedInterval = [intervalStart, selectedTime, intervalEnd];
        const startTime = this.getDateFromSeconds(sliderMinValue);
        const endTime = this.getDateFromSeconds(sliderMaxValue);
        const timelineTotalSeconds = sliderMaxValue - sliderMinValue;
        const timelineTotalHours = timelineTotalSeconds / 3600;
        const timelineTotalMinutes = timelineTotalSeconds / 60;
        const disabledIntervals = this.getDisabledIntervals();
        let tickNumber = 100;
        if (timelineTotalSeconds < tickNumber) tickNumber = timelineTotalSeconds;
        const tickMinutesToDiplay = this.getTickMinutesToDisplay(timelineTotalHours, timelineTotalMinutes);
        let tickSecondsToDisplay;
        let formatTick;
        if (timelineTotalMinutes < 5) {
            tickSecondsToDisplay = this.getTickSecondsToDisplay(timelineTotalMinutes);
            formatTick = (ms) => format(new Date(ms), 'HH:mm:ss');
        }

        const sliderClassName = selectedAsset ? 'timeline-slider' : 'ant-slider';
        const STEP_LENGTH = sliderStep;
        const STEP_SIZE_IN_SECONDS = sliderValueUnit == 'seconds' ? 1 : 60;
        const timelineWidth = sliderMaxValue * STEP_LENGTH * STEP_SIZE_IN_SECONDS;
        const sliderElement = document.getElementsByClassName('ant-slider-rail')[0];
        const sliderWidth = sliderElement ? sliderElement.getBoundingClientRect().width : 1;
        const sliderScaleX = sliderWidth / timelineWidth;

        return (
            <div
                className="toolbar"
                style={allowMultipleRangeSlider ? { height: '145px' } : {}}>
                <ul className="button-list">
                    {buttons.map((item, itemIndex) => (
                        <li
                            className={`${item.className || ''}`}
                            key={item.key || `nav-item-${itemIndex}`}>
                            <Tooltip title={item.tooltip || null}>
                                {item.menu ? (
                                    <Dropdown overlay={item.menu}>
                                        <button
                                            type="button"
                                            className={`btn btn-empty ${item.selected ? 'btn-selected' : ''}`}
                                            onClick={item.onClick}>
                                            {item.icon ? <CustomIcon name={item.icon} /> : null}
                                            {item.tag || null}
                                        </button>
                                    </Dropdown>
                                ) : (
                                    <button
                                        type="button"
                                        className={`btn btn-empty ${item.selected ? 'btn-selected' : ''}`}
                                        onClick={item.onClick}>
                                        {item.icon ? <CustomIcon name={item.icon} /> : null}
                                        {item.tag || null}
                                    </button>
                                )}
                            </Tooltip>
                        </li>
                    ))}
                </ul>
                <div className="search-box">
                    <Input.Search
                        ref={(ref) => referenceSearchInput(ref)}
                        id="map-search-box"
                        className="search"
                        size="small"
                        placeholder="Address or postcode..."
                        style={{
                            fontSize: '12px',
                            display: showCenterMapInput ? 'block' : 'none',
                        }} 
                    />
                </div>
                {hideSlider === false ? (
                    <div className="slider-block">
                        <div className="slider">
                            {allowMultipleRangeSlider ? (
                                <TimeRangeSlider
                                    ticksNumber={tickNumber}
                                    tickMinutesToDiplay={tickMinutesToDiplay}
                                    tickSecondsToDisplay={tickSecondsToDisplay}
                                    formatTick={formatTick}
                                    step={step}
                                    selectedInterval={selectedInterval}
                                    timelineInterval={[startTime, endTime]}
                                    disabledIntervals={disabledIntervals}
                                    onUpdateCallback={this.handleOnUpdate}
                                    onChangeCallback={this.sliderRangeChangeValue}
                                    error={error}
                                    timelineIncreaseOptions={timelineIncreaseOptions}
                                    selectedIncreaseOption={selectedIncreaseOption}
                                    onForwardCallback={forwardCallback}
                                    onRewindCallback={rewindCallback}
                                    onIncreaseOptionCallback={increaseOptionCallback}
                                    showResetBtn={showResetBtn}
                                    resetCallback={resetCallback}
                                    disabledIntervalChildrens={[<VideoFilledIconSvg key="video-filled-icon" />]}
                                    styles={timeRangeStyles}
                                    colours={timeRangeColours}
                                    isLive={isLive}
                                    selectedAsset={selectedAsset} />
                            ) : (
                                <>
                                    <Slider
                                        tipFormatter={this.formatTooltip}
                                        tooltipVisible
                                        getTooltipPopupContainer={() => document.getElementsByClassName('slider')[0]}
                                        step={1}
                                        min={sliderMinValue}
                                        max={sliderMaxValue}
                                        defaultValue={actualMapSlider}
                                        value={actualMapSlider}
                                        onChange={this.sliderChangeValue}
                                        className={sliderClassName}
                                        style={{
                                            zIndex: 100,
                                        }} 
                                    />
                                    {selectedAsset && (selectedDeviceTimeline && selectedDeviceTimeline.length) && (selectedDeviceGeofenceTimeline || selectedDeviceGeofenceTimeline.length) ? (
                                        <DeviceTimeline
                                            timelineWidth={timelineWidth}
                                            scaleX={sliderScaleX}
                                            data={selectedDeviceTimeline} 
                                            geofenceData={selectedDeviceGeofenceTimeline}
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                        {hideDatePicker === false && (
                            <MemoizedDatePicker
                                value={dayjs(mapAssetDate, 'DD/MM/YYYY')}
                                format="DD/MM/YYYY"
                                onChange={onDateChanged}
                                onFocus={enableOfflineMode}
                                className='map__ant-picker'
                                minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                            />
                        )}
                    </div>
                ) : null }
            </div>
        );
    }
}

Toolbar.defaultProps = {
    showCenterMapInput: false,
    mapAssetDate: dayjs().format('DD/MM/YYYY'),
    sliderChangeValue: () => {},
    sliderRangeChangeValue: () => {},
    referenceSearchInput: () => {},
    buttons: [],
    onDateChanged: () => {},
    hideSlider: false,
    sliderMaxValue: 1439,
    sliderMinValue: 0,
    sliderValue: 0,
    sliderStep: 5,
    isLive: false,
    hideDatePicker: false,
    sliderValueUnit: 'minutes',
    allowMultipleRangeSlider: false,
    sliderRangeMinValue: 0,
    sliderRangeMaxValue: 1439,
    highlightSlider: [],
    forwardCallback: () => {},
    rewindCallback: () => {},
    increaseOptionCallback: () => {},
    showResetBtn: false,
    resetCallback: () => {},
    markers: [],
    assets: [],
    selectedAsset: null,
    enableOfflineMode: () => {},
};

Toolbar.propTypes = {
    showCenterMapInput: PropTypes.bool,
    mapAssetDate: PropTypes.string,
    sliderChangeValue: PropTypes.func,
    sliderRangeChangeValue: PropTypes.func,
    referenceSearchInput: PropTypes.func,
    buttons: PropTypes.array,
    onDateChanged: PropTypes.func,
    hideSlider: PropTypes.bool,
    sliderMaxValue: PropTypes.number,
    sliderMinValue: PropTypes.number,
    sliderValue: PropTypes.number,
    sliderStep: PropTypes.number,
    isLive: PropTypes.bool,
    hideDatePicker: PropTypes.bool,
    sliderValueUnit: PropTypes.string,
    allowMultipleRangeSlider: PropTypes.bool,
    sliderRangeMinValue: PropTypes.number,
    sliderRangeMaxValue: PropTypes.number,
    highlightSlider: PropTypes.array,
    step: PropTypes.number.isRequired,
    forwardCallback: PropTypes.func,
    rewindCallback: PropTypes.func,
    increaseOptionCallback: PropTypes.func,
    showResetBtn: PropTypes.bool,
    resetCallback: PropTypes.func,
    markers: PropTypes.array,
    assets: PropTypes.array,
    selectedAsset: PropTypes.string,
    enableOfflineMode: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({}, dispatch) };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Toolbar);
